<template>
    <div class="seat-wrap">
        <div class="screen-wrap fx-column fx-center" :style="[screenStyle, width]" ref="seatScreen">
            <img class="screen" src="@/assets/images/movie/pm.png" />
            <span class="screen-name">{{title}}银幕</span>
        </div>
        <div class="seat-map--wrap" ref="seatMapWrap">
            <ul v-if="rowNameArray && rowNameArray.length > 0" class="seat-row_name" :style="[rowNameStyle]">
                <li v-for="item, index of rowNameArray" :key="index">{{ item }}</li>
            </ul>
            <div class="seat-map" :style="[width, height, seatMapStyle]" ref="seatMap" :class="animateFlag ? 'seat-animate' : ''">
                <div v-for="seatItem, index in data" :key="index" :style="seatPosition(seatItem.row, seatItem.column, seatItem.flag)" class="seat_item" :class="seatItem | getSeatClass" @click="toggle(seatItem)">
                    <img :src="seatItem | getSeatImage" class="seat_img" style="width: 100%;height: 100%;">
                </div>
            </div>
        </div>
        <transition name="van-fade">
            <div v-show="visible" class="seat-preview--wrap">
                <div class="seat-preview--content" :style="[width, height]">
                    <div v-for="seatItem, index in data" :key="index" :style="seatPosition(seatItem.row, seatItem.column, seatItem.flag)" class="seat_item" :class="seatItem | getSeatClass" @click="toggle(seatItem)">
                        <img :src="seatItem | getSeatImage" class="seat_img" style="width: 100%;height: 100%;">
                    </div>
                    <div class="seat-range" :style="[seatRangeStyle]"></div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import seatImageList from '../seat-image';
import { seatImagePathMap } from '../seat-image';
import Hammer from 'hammerjs';
import debounce from '@/libs/common-utils/debounce.js'
export default {
    props: {
        data: {
            type: Array,
        },
        title: {
            type: String,
        },
        seatArea: {
            type: Map,
            default: () => new Map()
        },
        maxSelect: {
            type: Number,
            default: () => 0
        }
    },
    data() {
        return {
            visible: false,
            animateFlag: false,
            buildData: false,
            remUnit: this.$config.remUnit,
            scaleOffsetLeft: 0,
            //滑动参数
            hammerOpts: {
                scale: 1,
                lastScale: 1,
                lastX: 0,
                lastY: 0,
                x: 0,
                y: 0,
                rowNameY: -15,
                lastRowNameY: -15
            },
            //座位参数
            seatOpts: 0.46,
            seatGap: 0.12,
            rowColRange: {
                rowRange: [],
                colRange: []
            },
            //情侣座
            coupleSeatMap: new Map(),
        }
    },
    watch: {
        data: {
            immediate: true,
            handler() {
                if (this.data && this.data.length > 0 && !this.buildData) {
                    this.buildData = true;
                    this.buildSeat();
                    this.$nextTick(() => {
                        this.transformToCenter();
                    })
                }
            }
        },
        'hammerOpts.scale': {
            immediate: true,
            handler() {
                this.$nextTick(() => {
                    let seatMap = this.$refs.seatMap;
                    if (seatMap) {
                        let scaleOffsetLeft = (seatMap.getBoundingClientRect().width - seatMap.clientWidth) / 2;
                        this.scaleOffsetLeft = scaleOffsetLeft;
                    }
                })
            }
        }
    },
    computed: {
        columnTotal() {
            let { colRange } = this.rowColRange;
            return Number(colRange[1]) - Number(colRange[0]) + 1;
        },
        rowTotal() {
            return this.rowNameArray.length;
        },
        rowNameArray() {
            let rowNameArray = [];
            let { rowRange } = this.rowColRange;
            for (let r = rowRange[0]; r <= rowRange[1]; r++) {
                let rowName = this.rowNameMap.get(r);
                //第一行空座位 直接忽略
                // if (rowNameArray.length == 0 && !rowName) continue;

                rowNameArray.push(rowName ? rowName : '');
            }

            return rowNameArray;
        },
        width() {
            return {
                width: this.columnTotal ? `${this.columnTotal * this.seatOpts + (this.columnTotal - 1) * this.seatGap}rem` : '100%'
            }
        },
        height() {
            return {
                height: `${this.rowTotal * this.seatOpts + (this.rowTotal - 1) * this.seatGap}rem`
            };
        },
        screenStyle() {
            let { x, scale } = this.hammerOpts;
            return {
                transform: `translate3d(${x / this.remUnit * scale}rem, 0, 0)`
            }
        },
        seatRangeStyle() {
            let { x, scale } = this.hammerOpts;
            return {
                transform: `translate3d(${-x + this.scaleOffsetLeft}px, 0, 0)`,
                width: `${window.innerWidth / scale}px`
            }
        },
        seatMapStyle() {
            let { x, y, scale } = this.hammerOpts;
            return {
                transform: `scale(${scale}) translate3d(${x / this.remUnit}rem, ${y / this.remUnit}rem, 0rem)`
            };
        },
        rowNameStyle() {
            let { y, scale } = this.hammerOpts;
            return {
                transform: `scale(${scale}) translate3d(0rem, ${(y) / this.remUnit}rem, 0rem)`
            }
        },
        selectedSeatList() {
            return this.data.filter((item) => item.isSelected == true)
        }
    },
    mounted() {
        this.initHammer();
    },
    methods: {
        test() {
            this.transformToCenter();
        },
        updateSeatStatus(seat) {
            this.$set(this.data, seat.seatIndex, seat);
        },
        //点击选中座位列表中的关闭按钮 删除座位
        deleteSeat(seat) {
            seat.isSelected = false;
            if (seat.lovegroup) {
                //删除的座位为情侣座时 相对应修改情侣座信息
                let oppositeSeat = this.coupleSeatMap.get(seat.lovegroup);
                if (oppositeSeat) {
                    oppositeSeat.isSelected = false;
                    this.updateSeatStatus(oppositeSeat);
                }
            }
            //改变已选座位集合 + 更新座位状态
            this.updateSeatStatus(seat);
        },
        toggle(seat) {
            if (seat.status != 1) return;
            this.animateFlag = true;

            seat = Object.assign({}, seat);
            seat.isSelected = !!!seat.isSelected;
            let selectedLength = this.selectedSeatList.length;
            let loveGroup = seat.lovegroup;

            selectedLength += seat.isSelected == false ? 0 : (loveGroup ? 2 : 1);
            if (seat.isSelected && selectedLength > this.maxSelect) {
                this.$toast(`最大可选${this.maxSelect}个座位`);
                return;
            }

            if (loveGroup) {
                let oppositeSeat = this.coupleSeatMap.get(loveGroup);
                if (oppositeSeat) {
                    oppositeSeat.isSelected = seat.isSelected;
                    this.updateSeatStatus(oppositeSeat);
                }
            }
            this.updateSeatStatus(seat);
            if (!seat.isSelected) return;
            //选中座位则定位至中间
            this.hammerOpts.scale = 1;
            this.hammerOpts.lastScale = 1;

            let seatMapWrap = this.$refs.seatMapWrap.getBoundingClientRect();
            this.$nextTick(() => {
                let event = window.event;
                let target = event.target;
                let rect = target.getBoundingClientRect();
                let deltaX = window.innerWidth / 2 - rect.left - rect.width / 2;
                // let deltaY = window.innerHeight / 2 - rect.top - rect.height / 2;
                let deltaY = seatMapWrap.bottom / 2 - rect.top - rect.height / 2;
                this.hammerOpts.x += deltaX;
                this.hammerOpts.y += deltaY;
                this.hammerOpts.lastX = this.hammerOpts.x;
                this.hammerOpts.lastY = this.hammerOpts.y;
            })

        },
        seatPosition(row, col, flag) {
            let side = this.seatOpts;
            let gap = this.seatGap;
            let { rowRange, colRange } = this.rowColRange;
            let firstRow = rowRange[0];
            let firstCol = colRange[0];
            if (flag == 2) {
                return `top: ${(row - firstRow) * (side + gap)}rem;left: ${(gap + side) * (col - firstCol) - gap}rem;`
            }
            return `top: ${(row - firstRow) * (side + gap)}rem;left: ${(gap + side) * (col - firstCol)}rem;`
        },
        buildSeat() {
            //用于获取座位行|列的 最大最小范围
            let defaultSeat = this.data[0];
            let rowRange = [defaultSeat.row, defaultSeat.row];
            let colRange = [defaultSeat.column, defaultSeat.column];
            //用于标记行号名称
            let rowNameMap = new Map();
            this.data.forEach((item, index) => {
                item.seatIndex = index;
                this.getRowColRange(item, rowRange, colRange);

                if (!rowNameMap.has(parseInt(item.row))) {
                    rowNameMap.set(item.row, item.rowName);
                }

                //2.构建不同区域的座位价格
                if (this.seatArea.size > 0) {
                    let areaInfo = this.seatArea.get(item.areaId);
                    areaInfo?.price ? item.realPrice = Number(areaInfo.price) : '';
                    areaInfo ? item.areaPic = seatImageList[parseInt(item.flag)][areaInfo.index] : '';
                }

                //4.当该座为情侣座时 赋值相对应的座位为自己 用于取情侣座 例如: 该座=1L, 则coupleSeatMap.set('1R', 该座);
                if (item.lovegroup) {
                    let loveGroup = item.lovegroup;
                    let position = loveGroup.split('')[loveGroup.length - 1];
                    let oppositePosition = position == "R" ? "L" : position == "L" ? "R" : "";
                    let oppositeKey = '' + parseInt(loveGroup) + oppositePosition;
                    this.coupleSeatMap.set(oppositeKey, item);
                }

            })

            this.rowNameMap = rowNameMap;
            this.rowColRange = {
                rowRange,
                colRange
            }
        },
        getRowColRange(item, rowRange, colRange) {
            if (item.row < rowRange[0]) {
                rowRange[0] = item.row
            }

            if (item.row > rowRange[1]) {
                rowRange[1] = item.row
            }

            if (item.column < colRange[0]) {
                colRange[0] = item.column
            }

            if (item.column > colRange[1]) {
                colRange[1] = item.column
            }
        },
        transformToCenter() {
            let { width } = this.$refs.seatMap.getBoundingClientRect();
            let windowWidth = window.innerWidth;
            let offsetLeft = (width - this.$refs.seatMap.clientWidth) / 2;
            this.hammerOpts.x = (windowWidth - width) / 2 + offsetLeft;
            this.hammerOpts.lastX = this.hammerOpts.x;
            this.hammerOpts.y = 0;
            this.hammerOpts.lastY = 0;
        },
        initHammer() {
            // hammer.get('pan').set({direction: Hammer.DERECTION_ALL});
            const seatMapWrap = this.$refs.seatMapWrap;
            const hammer = new Hammer(seatMapWrap);
            let fault = 2 * (this.seatOpts + this.seatGap) * this.remUnit

            hammer.get('pan').set({ direction: Hammer.DIRECTION_ALL });

            hammer.on('pan', (e) => {
                this.data && this.data.length > 0 ? this.visible = true : '';
                debounce(() => {
                    this.visible = false;
                }, 1500)
                this.animateFlag = false;
                this.hammerOpts.x = this.hammerOpts.lastX + e.deltaX;
                this.hammerOpts.y = this.hammerOpts.lastY + e.deltaY;
            })

            hammer.on('panend', (e) => {
                let seatMap = this.$refs.seatMap;
                let { left, top, height, width } = seatMap.getBoundingClientRect();
                let seatMapWrapBounding = seatMapWrap.getBoundingClientRect();
                let windowWidth = window.innerWidth;
                // let scaleOffsetLeft = (width - seatMap.clientWidth) / 2; // 缩放后的左偏差值
                // let scaleOffsetTop = (height - seatMap.clientHeight) / 2; // 缩放后的上偏差值
                let maxX = windowWidth - fault; //右边边界值
                let minX = 0 - width + fault; //左边边界值 本身宽度 + 容错值
                let maxY = seatMapWrapBounding.top - height + fault; //上边边界值
                let minY = seatMapWrapBounding.bottom - fault; //下边边界值

                if (left > maxX) {
                    this.transformToCenter();
                }
                if (left < minX) {
                    this.transformToCenter();
                }

                if (top < maxY) {
                    this.transformToCenter();
                }
                if (top > minY) {
                    this.transformToCenter();
                }

                let { x, y } = this.hammerOpts;
                this.hammerOpts = {
                    ...this.hammerOpts,
                    lastX: x,
                    lastY: y,
                }
            });
            hammer.get('pinch').set({ enable: true });

            hammer.on('pinch', (e) => {
                this.hammerOpts.scale = Math.max(0.7, Math.min(this.hammerOpts.lastScale * (e.scale), 1.2));
            })

            hammer.on('pinchend', (e) => {
                this.hammerOpts.lastScale = this.hammerOpts.scale;
            })
        }
    },
    filters: {
        getSeatImage(seat) {
            //如果该座位为标记的区域座位 直接返回座位的图片
            if (seat.areaPic && !seat.isSelected && seat.status == 1) return seat.areaPic.path;
            let seatImageKey = `${seat.status}:${seat.flag}`;
            seatImageKey += seat.isSelected ? ':1' : '';
            let seatImage = seatImagePathMap.get(seatImageKey);
            return seatImage ? seatImage.path : '';
        },
        getSeatClass(seat) {
            if (!seat) return "";
            if (seat.flag == 1) {
                return "seat_item-couple-left";
            }

            if (seat.flag == 2) {
                return "seat_item-couple-right";
            }
            return "";
        }
    }
}
</script>

<style lang="scss" scoped>
.seat-wrap {
	width: 100%;
	height: 100%;
	@include scroll-bar-none();
	overflow: hidden;
	display: flex;
	flex-direction: column;
	position: relative;
	.screen-wrap {
		position: relative;
		z-index: 9;
		background-color: #eff1f8;
		padding: 4px 0;
		padding-bottom: 20px;
		// .screen {
		// 	display: inline-block;
		// 	background-color: #f90040;
		// 	height: 5px;
		// 	width: 100px;
		// 	margin-bottom: 4px;
		// }
		.screen-name {
			color: $tips-color;
		}
	}
	.seat-map--wrap {
		@include scroll-bar-none();
		position: relative;
		flex: 1;
		// border:1px solid green;
		.seat-row_name {
			position: absolute;
			width: 0.55rem;
			padding: 15px 0;
			background-color: rgba(0, 0, 0, 0.4);
			border-radius: 9px;
			z-index: 10;
			left: 8px;
			top: -15px;
			box-sizing: initial;
			li {
				height: calc(0.46rem + 0.12rem);
				line-height: calc(0.46rem);
				text-align: center;
				color: #fff;
				width: 100%;
			}
			li:nth-last-child(1) {
				height: 25px;
			}
		}

		.seat-map {
			position: absolute;
			webkit-transform: translateZ(0);
			-moz-transform: translateZ(0);
			-ms-transform: translateZ(0);
			-o-transform: translateZ(0);
			transform: translateZ(0);
			// transform-origin: left;
			// transform-origin: 0% 0%;
			// border:1px solid red;
			&.seat-animate {
				transition: transform 0.3s ease-in-out;
			}
			.seat_item {
				position: absolute;
				height: 0.46rem;
				width: 0.46rem;

				&.seat_item-couple-left {
					width: calc(0.46rem + 0.06rem) !important;
					border-right: 0;
				}
				&.seat_item-couple-right {
					width: calc(0.46rem + 0.06rem) !important;
					border-left: 0;
				}
			}

			// &::before,
			// &::after {
			// 	content: "";
			// 	position: absolute;
			// 	border-style: dotted;
			// 	border-color: #e42f46;
			// 	border-width: 0;
			// 	z-index: 2;
			// 	pointer-events: none;
			// }

			&::before {
				width: 0;
				height: 100%;
				border-left-width: 1px;
				left: 50%;
				top: 0;
			}
		}
	}

	//缩略图预览
	.seat-preview--wrap {
		position: absolute;
		left: 0;
		top: 0;
		background: rgba(0, 0, 0, 0.3);
		transform: scale(0.2);
		transform-origin: 0% 0%;
		display: flex;
		z-index: 12;
		padding: 35px;
        overflow: hidden;
		.seat-preview--content {
			position: relative;
			flex: 1;

			.seat_item {
				position: absolute;
				height: 0.46rem;
				width: 0.46rem;

				&.seat_item-couple-left {
					width: calc(0.46rem + 0.06rem) !important;
					border-right: 0;
				}
				&.seat_item-couple-right {
					width: calc(0.46rem + 0.06rem) !important;
					border-left: 0;
				}
			}
			.seat-range {
				height: 100%;
				border: 5px solid #7cfc00;
				position: absolute;
			}
		}
	}
}
</style>
