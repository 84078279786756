const seatImageList = [
    [
        { path: require("@/assets/images/seat/1.png") },
        { path: require("@/assets/images/seat/2.png") },
        { path: require("@/assets/images/seat/3.png") },
        { path: require("@/assets/images/seat/4.png") },
        { path: require("@/assets/images/seat/5.png") },
        { path: require("@/assets/images/seat/5.png") },
        { path: require("@/assets/images/seat/5.png") },
    ],
    [
        { path: require("@/assets/images/seat/1L.png") },
        { path: require("@/assets/images/seat/2L.png") },
        { path: require("@/assets/images/seat/3L.png") },
        { path: require("@/assets/images/seat/4L.png") },
        { path: require("@/assets/images/seat/5L.png") },
    ],
    [
        { path: require("@/assets/images/seat/1R.png") },
        { path: require("@/assets/images/seat/2R.png") },
        { path: require("@/assets/images/seat/3R.png") },
        { path: require("@/assets/images/seat/4R.png") },
        { path: require("@/assets/images/seat/5R.png") },
    ]
]

/**
 * key = 'status:flag'
 */
export const seatImagePathMap = new Map(
    [
        ['0:0', { path: require('@/assets/images/seat/seat_sold.png') }], //已售的普通座
        ['0:1', { path: require('@/assets/images/seat/seat_couple_sold_left.png') }], //已售的情侣座 左
        ['0:2', { path: require('@/assets/images/seat/seat_couple_sold_right.png') }], //已售的情侣座 右

        ['1:0', { path: require('@/assets/images/seat/seat_selectable.png') }], //可选的普通座
        ['1:1', { path: require('@/assets/images/seat/seat_couple_selectable_left.png') }], //可选的情侣座 左
        ['1:2', { path: require('@/assets/images/seat/seat_couple_selectable_right.png') }], //可选的情侣座 右

        ['1:0:1', { path: require('@/assets/images/seat/seat_selected2.png') }], //已选的普通座
        ['1:1:1', { path: require('@/assets/images/seat/seat_couple_selected_left2.png') }], //已选的情侣座 左
        ['1:2:1', { path: require('@/assets/images/seat/seat_couple_selected_right2.png') }], //已选的情侣座 右
        // ['1:2', { path: require('@/assets/images/seat/seat_couple_sold_right.png') }], //已售的情侣座 右
        // ['0:2', { path: require('@/assets/images/seat/seat_couple_sold_right.png') }], //已售的情侣座 右
        // ['0:2', { path: require('@/assets/images/seat/seat_couple_sold_right.png') }], //已售的情侣座 右
        // ['0:2', { path: require('@/assets/images/seat/seat_couple_sold_right.png') }], //已售的情侣座 右
        // ['0:2', { path: require('@/assets/images/seat/seat_couple_sold_right.png') }], //已售的情侣座 右
        // ['0:2', { path: require('@/assets/images/seat/seat_couple_sold_right.png') }], //已售的情侣座 右
        // ['']

    ]
)


export default seatImageList;
